import React from "react"
import { Link } from "gatsby"
import styled from '@emotion/styled';

import getSongNumberText from "../../functions/getSongNumberText"

const List = styled.div`
  padding: 16px 0;
`
const A = styled(Link)`
  text-decoration: none;
`

const ArtistListItem = ({ node }) => {
  const { name, twitterId, contents } = node;
  const songNumberText = getSongNumberText(contents.length);
  return (
    <List>
      <A to={`/artists/${twitterId}`}>{name}さんがおすすめする音楽{songNumberText}</A>
    </List>
  );
}

export default ArtistListItem
