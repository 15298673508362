import React from "react"
import styled from "@emotion/styled"
import ArtistListItem from "./ArtistListItem"

const ListContainer = styled.div`
`
const ListTitle = styled.h2`
`

const ArtistList = ({ edges, title }) => {
  return (
    <ListContainer>
      <ListTitle>{title}</ListTitle>

      {edges.map(({ node }) => <ArtistListItem key={node.twitterId} node={node} />)}
    </ListContainer>
  );
}

export default ArtistList
