import React from 'react'
import styled from '@emotion/styled'

const INQUIRY_URL = "https://docs.google.com/forms/d/e/1FAIpQLSdftswTenU6D0519BrxNmWnyLeLUWjLCatH5v8EWN-ecZy77A/viewform?usp=sf_link"

const FooterComponent = styled.footer`
  margin: 16px 0;
  display: flex;
  align-items: center;

  flex-direction: column;
  justify-content: center;
  @media screen and (min-width: 600px) {
    flex-direction: row;
    justify-content: flex-end;
  }
`
const A = styled.a`
  font-size: 14px;
  padding: 12px 8px;
  margin: 0 8px;
  text-decoration: none;
`
const Small = styled.small`
  padding: 12px 8px;
  margin: 0 8px;
`

const Footer = () => {
  return (
    <FooterComponent>
      <A href={INQUIRY_URL} rel="noreferer noopener">掲載リクエスト</A>
      <A href={INQUIRY_URL} rel="noreferer noopener">お問い合わせ</A>
      <Small>&copy; 2021 サーチマップ</Small>
    </FooterComponent>
  );
}

export default Footer;
