import React from "react"
import { Link } from "gatsby"
import styled from '@emotion/styled';

import Icon from "../assets/icon.png"

const Container = styled.header`
  font-size: 24px;
  font-weight: bold;
  margin-top: 16px;
  display: flex;
  align-items: center;

  justify-content: center;
  @media screen and (min-width: 600px) {
    justify-content: flex-start;
  }
`
const Img = styled.img`
  margin-right: 8px;
`
const StyledLink = styled(Link)`
  color: #eee;
  text-decoration: none;
`

const Header = ({ isLink = true }) => {
  return (
    <Container>
      <Img src={Icon} alt="" width="36" height="36" />
      {isLink ? (
	<StyledLink to="/" style={{ color: "#eee" }}>RecoMusic</StyledLink>
      ) : (
        <span>RecoMusic</span>
      )}
    </Container>
  )
};

export default Header